#App {
  background-color: #282c34;
  min-height: 100vh;
  color: white;
  text-align: center;
}

#AppContent {
  padding-bottom: 5rem;
}

#AppLogo {
  pointer-events: none;
  max-height: 10rem;
}

#AppHeader {
  height: 15vh;
  display: flex;
  justify-items: center;
  justify-content: center;
}

#Login {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  height: 100vh;
}

.App-link {
  color: #61dafb;
}

.playlists {
  width: 90%;
}

.list-group {
  border-radius: 8px !important;
}

@media screen and (min-width: 768px) {
  .playlists {
    width: 60%;
  }
}

.loading {
  width: 100%;
  background-color: #282c34;
  display: flex;
  justify-content: center;
  justify-items: center;
  align-content: center;
  align-items: center;
}

.fullscreen {
  width: 100vw;
  height: 100vh;
}

.border-full-dark {
  border-color: #121418 !important;
}

.modal-header {
  border-bottom: none !important;
}

.modal-border {
  border-top: 1px solid;
  border-bottom: 1px solid;
}

.blur {
  backdrop-filter: blur(4px);
}

.dimmed {
  backdrop-filter: brightness(30%);
}

.gap {
  gap: 0.5rem;
}
